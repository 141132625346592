<template>
  <div>
    <sm-loader v-if="getSecureCardsData.loading" />
    <div v-else class="flex flex-col text-14 mb-5 mt-2 font-bold">
      <h2 class="text-2xl font-bold">Secure Cards</h2>
    </div>

    <div class="border border-solid border-blue-200">
      <datatable
        :data="secureCards.data"
        :columns="secureCards.columns"
        :filters="secureCards.filters"
        :query="secureCards.query"
        :loading="secureCards.loading"
        :ajax="true"
        :ajax-pagination="true"
        :url="`${this.$baseurl}/admin/personal/secure-cards`"
        :actions="actions"
        :search-field="searchField"
        ref="table"
      >
        <template #header>
          <search-form
            v-model="secureCards.query"
            class="mb-5"
            placeholder="Search for user's name"
            @submit="reloadData"
          />

          <div class="mb-2">
            <span class="font-bold mb-2 mr-2 text-sm uppercase">Filters:</span>
          </div>
          <div class="flex items-center mb-5">
            <template v-for="(filter, filterIndex) in secureCards.filters">
              <div
                :key="filterIndex"
                class="processor-filter-item"
                :class="{ active: currentFilter === filter }"
                @click.prevent="setFilter(filter)"
              >
                {{ filter.title }}
              </div>
            </template>
          </div>
        </template>
      </datatable>
    </div>

    <modal className="w-full md:w-2/5 xl:w-3/10 pt-10" ref="markAsPaidModal">
      <h4 class="text-2xl font-bold mb-16">Mark As Paid</h4>

      <div>
        <template v-if="selectedSecureCard">
          <p class="text-gray-500">Name</p>
          <div class="rounded p-2">
            <div class="text-xl font-bold" v-if="selectedSecureCard">
              {{ selectedSecureCard.user.name }}
              {{ selectedSecureCard.user.last_name }}
            </div>
          </div>

          <p class="text-gray-500">Payment Status</p>
          <div class="rounded p-2">
            <div class="text-xl font-bold" v-if="selectedSecureCard">
              {{ selectedSecureCard.payment_status ? 'Paid' : 'Not Paid' }}
            </div>
          </div>
        </template>
        
        <div class="text-center" v-if="selectedSecureCard">
          <button
            v-if="canDo('credpal:can_mark_secure_card_as_paid')"
            @click.prevent="markAsPaid(selectedSecureCard)"
            class="button px-5 py-3 rounded-sm text-sm inline-block bg-blue-500 text-white hover:bg-blue-600"
            :disabled="loading || !selectedSecureCard.receipt_path"
          >
            <template v-if="loading">Updating...</template>
            <template v-else>{{
              !selectedSecureCard.payment_status ? 'Confirm' : 'Unconfirm'
            }}</template>
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  props: {
    searchQuery: {
      type: String,
      default: '',
    },
    searchField: {
      type: [HTMLInputElement],
      default: null,
    },
  },
  data() {
    return {
      selection: [],
      checkmark: require('@/assets/checkmark-base.svg'),
      currentFilter: 'all',
      secureCards: this.$options.resource([], {
        query: '',
        selected: null,
        filters: [
          { name: 'all', title: 'All' },
          { name: 'paid', title: 'Paid' },
          { name: 'pending-payment', title: 'Pending Payment' },
        ],
        columns: [
          {
            name: 'user_id',
            th: 'User ID',
          },
          {
            name: 'name',
            th: 'Name',
            render: (secureCard) =>
              `${secureCard?.user?.name} ${
                secureCard?.user?.last_name || 'N/A'
              }`,
          },
          {
            name: 'email',
            th: 'Email',
            render: (secureCard) => `${secureCard?.user?.email}`,
          },
          {
            name: 'phone_no',
            th: 'Phone No.',
            render: (secureCard) => `${secureCard?.user?.phone_no}`,
          },
          {
            name: 'status',
            th: 'Status',
            render: (secureCard) => {
              return this.statusBadge(
                secureCard?.user?.status,
                secureCard?.user
              );
            },
          },
          {
            name: 'payment_status',
            th: 'Payment Status',
            render: (secureCard) => {
              if (secureCard?.payment_status) {
                return '<span class="text-green-500">Paid</span>';
              }
              return '<span class="text-red-500">Not Paid</span>';
            },
          },
          {
            name: 'receipt_path',
            th: 'Reciept',
            render: secureCard => {
            return `
                  <div class="flex items-center">
                    <button
                      class="btn btn-blue btn-sm mr-5"
                      data-click="viewReceipt(${secureCard.id})"
                    >
                      View
                    </button>
                  </div>
                `;
            }
          },
          {
            name: 'receipt_upload_at',
            th: 'Receipt Uploaded',
             render: (secureCard) => {
               if (!secureCard?.receipt_upload_at) {
              return "N/A";
            }
            return this.$moment(secureCard.receipt_upload_at).format("MMM Do YYYY");
            }
          },
          {
            name: 'created_at',
            th: 'Date',
            render: (secureCard, date) =>
              this.$moment(date).format('MMM Do YYYY'),
          },
        ],
        successes: [],
        failures: [],
      }),
      loading: false,
      selectedSecureCard: {
        user: {},
        receipt_path: '',
      },
      form: this.$options.basicForm(['status']),
      getSecureCardsData: this.$options.resource({}),
      actions: [
        {
          text: 'Mark As Paid',
          class:
            'border-blue-500 text-white-500 rounded-sm px-4 py-2 hover:bg-gray-200',
          action: this.openMarkAsPaidModal, //this.viewReceipt,
        },
      ],
    };
  },
  mounted() {
    this.$options.listenForClick.apply(this);
  },
  methods: {
    click(defaultUser) {
      this.$router.push({
        name: 'staff-view',
        params: { staffId: defaultUser?.user_id },
      });
    },
    reloadData() {
      this.$refs.table.loadAjaxData();
    },
    setFilter(filter) {
      if (this.currentFilter === filter) {
        this.currentFilter = null;
      } else {
        this.currentFilter = filter;
      }

      this.$refs.table.clickedFilter(this.currentFilter);
    },
    openMarkAsPaidModal(account) {
      this.selectedSecureCard = account;
      this.$refs.markAsPaidModal.open();
    },
    async viewReceipt(receipt) {
      await this.sendRequest('admin.secureCards.viewReceipt', receipt, {
        success: (response) => {
          window.open(
            response.data.url,
            '_blank' // <- This is what makes it open in a new window.
          );
        },
        error: () => {
          this.$error({
            title: 'Receipt Error',
            body: 'There was an error.',
            button: 'Okay',
          });
        },
      });
    },
    async markAsPaid(receipt) {
      this.loading = true;
      await this.sendRequest('admin.secureCards.markAsPaid', receipt.id, {
        data: {},
        success: (response) => {
          console.log('response', response);
          this.$refs.markAsPaidModal.close();
          this.reloadData();
          this.$success({
            title: this.selectedSecureCard.payment_status ? 'Secure Card Unmarked As Paid Successfully' : 'Secure Card Marked As Paid Successfully',
            body: response?.data?.message ?? 'There was an error.',
            button: 'Okay',
          });
        },
        error: (error) => {
          this.$error({
            title: 'Error',
            body: error.response?.data?.message ?? 'There was an error.',
            button: 'Okay',
          });
        },
      });
      this.loading = false;
    },
  },
};
</script>
