<template>
  <div>
    <sm-loader v-if="getDeclineUsersData.loading" />
    <div class="flex flex-col text-14 mb-5 mt-2 font-bold" v-else>
      <h2 class="text-2xl font-bold">Declined Users</h2>
    </div>

    <div class="border border-solid border-blue-200">
      <datatable
        :data="declineUsers.data"
        :columns="declineUsers.columns"
        :query="declineUsers.query"
        :loading="declineUsers.loading"
        :ajax="true"
        :ajax-pagination="true"
        :url="`${this.$baseurl}/admin/personal/users/status/declined`"
        :search-field="searchField"
        :on-click="click"
        ref="table"
      >
        <template #header>
          <search-form
            v-model="declineUsers.query"
            class="mb-5"
            placeholder="Search for user's name"
            @submit="reloadData"
          />
        </template>
      </datatable>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    searchQuery: {
      type: String,
      default: ''
    },
    searchField: {
      type: [HTMLInputElement],
      default: null
    }
  },
  data() {
    return {
      selection: [],
      checkmark: require('@/assets/checkmark-base.svg'),
      currentFilter: null,
      declineUsers: this.$options.resource([], {
        query: '',
        selected: null,
        columns: [
          {
            name: 'user_id',
            th: 'User',
            render: declineUser =>
            `${declineUser?.name} ${declineUser?.last_name || 'N/A'}`
          },
          {
            name: 'email',
            th: 'Email',
            render: declineUser => `${declineUser?.email}`
          },
          {
            name: 'phone_no',
            th: 'Phone No.',
            render: declineUser => `${declineUser?.phone_no}`
          },
          {
            name: 'created_at',
            th: 'Date',
            render: (declinedUser, created_at) =>
              this.$options.filters.dateFormat(created_at, 'D, dd M Y h:ia'),
          },
        ],
        successes: [],
        failures: []
      }),
      form: this.$options.basicForm(['status']),
      getDeclineUsersData: this.$options.resource({})
    };
  },
  mounted() {
    this.$options.listenForClick.apply(this);
  },
  methods: {
    click(defaultUser) {
      this.$router.push({
        name: "staff-view",
        params: { staffId: defaultUser?.user_id }
      });
    },
    reloadData() {
      this.$refs.table.loadAjaxData();
    },
  }
};
</script>
